import {RemixBrowser} from '@remix-run/react';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {bootstrap} from '#/app/bootstrap.js';

bootstrap();

const hydrate = () => {
  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <RemixBrowser />,
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
